<template>
  <v-container>
    <base-loading v-if="loading" />

    <template v-else-if="userCoinsIsGetted">
      <InfoUserCoins :userCoins="userCoins" />
      <ListPrizes class="my-4" :prizes="prizes" @prizeRedeemed="loadContent" />
    </template>
    <ErrorRetryComponent
      v-else
      :errorMessage="$t('errorGettingsUserCoins')"
      :retryFetchAction="loadContent"
    />
  </v-container>
</template>

<script>
import {
  userInfoStoreGetters,
  userInfoStoreActions,
} from "@/store/modules/userInfo/constNames";
import { userCtrl, prizesCtrl } from "@/controllers";

export default {
  name: "PrizesContainerComponent",
  components: {
    ErrorRetryComponent: () =>
      import("@/components/errors/ErrorRetryComponent.vue"),
    InfoUserCoins: () => import("./InfoUserCoins.vue"),
    ListPrizes: () => import("./ListPrizes.vue"),
  },
  data() {
    return {
      userCoins: null,
      prizes: [],
      loading: true,
    };
  },
  async mounted() {
    await this.loadContent();
  },
  methods: {
    async getUserInfo() {
      const userId = this.$store.getters[userInfoStoreGetters.GET_USER_ID];
      const { data: user } = await userCtrl.getUserInfo(userId, true);
      this.$store.dispatch(userInfoStoreActions.SAVE_USER, user);
      this.userCoins = user.coins ?? 0;
    },
    async getPrizes() {
      const { prizes } = await prizesCtrl.getPrizes();
      this.prizes = prizes;
    },
    async loadContent() {
      try {
        this.loading = true;
        await this.getUserInfo();
        await this.getPrizes();
      } catch (error) {
        const errorPayload = getErrorPayloadForSnackbar(error);
        this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    userCoinsIsGetted() {
      return this.userCoins !== null;
    },
  },
};
</script>
